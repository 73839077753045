<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <iframe
      style="position: absolute; width: 100%; height: 97%;"
      :src="frameSrc"
      frameborder="0"
      @load="onFrameLoad"
    ></iframe>
  </div>
</template>

<script>
  import path from 'path'
  import Vue from 'vue'
  export default {
    name: 'Docs',
    data () {
      return {
        frameSrc: null
      }
    },
    methods: {
      onFrameLoad () {
        document.cookie = 'docs.token=' + this.$keycloakmanager.token + ';secure;path=' + Vue.prototype.$EMSWEB_DOCS_PATH
      }
    },
    created () {
      document.cookie = 'docs.token=' + this.$keycloakmanager.token + ';secure;path=' + Vue.prototype.$EMSWEB_DOCS_PATH
      this.frameSrc = (
        location.protocol + '//' +
        path.join(
          location.host, Vue.prototype.$EMSWEB_DOCS_PATH,
          this.$root.$i18n.locale, '/latest/index.html'
        )
      )
      console.log(this.frameSrc)
    }
  }
</script>
